import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import { useLocation } from "react-router-dom";
import { azureBaseUrl } from "../config";
import i18next from "i18next";
import ProgressBar from "./ProgressBar";

function ConfirmSubscription() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const location = useLocation();

  // Bei einem Pfadwechsel müssen die Topics neugeladen werden
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("token")) {
      setUserMessage("Ihr Link ist ungültig.");
      setIsLoading(false);
      return;
    }
    // Daten über Azure-Function abrufen
    fetch(`${azureBaseUrl}ConfirmSubscription`, {
      method: "POST",
      body: JSON.stringify({ token: urlParams.get("token") }),
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setUserMessage(
              data.success
                ? "Hiermit können wir Ihnen Ihre Anmeldung zum Newsletter der BAMAC Group bestätigen."
                : "Es konnte keine Registrierung gefunden werden. Bitte registrieren Sie sich erneut."
            );
            setSuccess(true);
          });
        } else {
          setUserMessage("Die Bestätigung Ihrer Newsletter Registrierung ist leider fehlgeschlagen. Bitte versuchen Sie es später erneut.");
        }
      })
      .catch(() => setUserMessage("Die Bestätigung ist fehlgeschlagen."))
      .finally(() => setIsLoading(false));
  }, [location.pathname]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      <div id="page-container">
        <div id="content-wrap">
          <div id="container" className="container">
            <div id="loadedContent">
              <div>
                <h2>{success ? t("Vielen Dank für Ihre Newsletter Anmeldung") : t("Registrierung für den Newsletter")}</h2>
              </div>
              {userMessage !== "" && (
                <div className="userMessageWrapper">
                  <ProgressBar active={success ? 3 : 1} />
                  <div id="userMessage">{t(userMessage)}</div>
                  <div className="backContainer">
                    <span>{t("Zurück zur Startseite der ")}</span>
                    <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
                      <img style={{ width: 120 }} src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConfirmSubscription;
