import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { Themes, ICategory } from "./Themes";
import ModalScreen from "./Modal";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import { useLocation } from "react-router-dom";
import { azureBaseUrl } from "../config";

interface IProps {
  isUnsubscribe: boolean;
}

function ChangeSubscription({ isUnsubscribe }: IProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const [email, setEmail] = useState("");
  const [topics, setTopics] = useState<ICategory[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("token") || !urlParams.has("email")) {
      setUserMessage("Ihr Link ist ungültig.");
      setIsLoading(false);
      return;
    }
    setEmail(urlParams.get("email") || "");

    // Daten über Azure-Function abrufen
    fetch(`${azureBaseUrl}gettopics?token=${urlParams.get("token")}&email=${urlParams.get("email")}&language=${i18next.language}`)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => setTopics(data));
        } else {
          setUserMessage("Es liegt keine Newslettersubscription vor.");
        }
      })
      .catch(() => setUserMessage("Beim Abrufen der Seite ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut."))
      .finally(() => setIsLoading(false));
  }, [location.pathname]);

  useEffect(() => {
    if (userMessage === "") {
      return;
    }
    document.getElementById("userMessage")?.scrollIntoView({ block: "center" });
  }, [userMessage]);
  const changeSubscription = () => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(window.location.search);

    // Auswahl der Themen abrufen
    const selectedTopics: string[] = [];
    topics.forEach((a_category) => {
      a_category.topics.forEach((topic) => {
        if (topic.isSubscribed) {
          selectedTopics.push(topic.title);
        }
      });
    });

    fetch(`${azureBaseUrl}updatetopics`, {
      method: "POST",
      body: JSON.stringify({
        token: urlParams.get("token"),
        email: urlParams.get("email"),
        topics: selectedTopics,
        language: i18next.language,
      }),
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          setUserMessage("Ihre Anpassung wurden erfolgreich gespeichert.");
          return;
        }

        setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut.");
        return;
      })
      .catch(() => setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut."))
      .finally(() => setIsLoading(false));
  };

  const unsubscribe = () => {
    setIsLoading(true);

    const urlParams = new URLSearchParams(window.location.search);

    fetch(`${azureBaseUrl}unsubscribe`, {
      method: "POST",
      body: JSON.stringify({
        token: urlParams.get("token"),
        email: urlParams.get("email"),
      }),
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          setUserMessage("Sie wurden erfolgreich vom Newsletter abgemeldet.");
        } else {
          setUserMessage("Bei der Abmeldung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
        }
        return;
      })
      .catch(() => setUserMessage("Bei der Abmeldung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."))
      .finally(() => {
        setIsLoading(false);
        window.scrollTo({ top: 0 });
      });
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      <div id="container" className="container">
        <ModalScreen
          title={t("Wollen Sie sich wirklich abmelden?")}
          primaryAction={unsubscribe}
          isOpen={isModalOpen}
          askToClose={() => setIsModalOpen(false)}
        />
        <div id="loadedContent">
          <div>
            <h2>{t("Newsletter")}</h2>
          </div>
          {userMessage !== "" ? (
            <React.Fragment>
              <div className="userMessageWrapper">
                <div id="userMessage">{t(userMessage)}</div>
                <div className="backContainer">
                    <span>{t("Zurück zur Startseite der ")}</span>
                    <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
                      <img style={{ width: 120 }} src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
                    </a>
                  </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <div>
                  <svg className="emailIcon" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.025 17H3.707l5.963-5.963L12 12.83l2.33-1.794 1.603 1.603a5.463 5.463 0 0 1 1.004-.41l-1.808-1.808L21 5.9v6.72a5.514 5.514 0 0 1 1 .64V5.5A1.504 1.504 0 0 0 20.5 4h-17A1.504 1.504 0 0 0 2 5.5v11A1.5 1.5 0 0 0 3.5 18h9.525c-.015-.165-.025-.331-.025-.5s.01-.335.025-.5zM3 16.293V5.901l5.871 4.52zM20.5 5c.009 0 .016.005.025.005L12 11.57 3.475 5.005c.009 0 .016-.005.025-.005zm-2 8a4.505 4.505 0 0 0-4.5 4.5 4.403 4.403 0 0 0 .05.5 4.49 4.49 0 0 0 4.45 4h.5v-1h-.5a3.495 3.495 0 0 1-3.45-3 3.455 3.455 0 0 1-.05-.5 3.498 3.498 0 0 1 5.947-2.5H20v.513A2.476 2.476 0 0 0 18.5 15a2.5 2.5 0 1 0 1.733 4.295A1.497 1.497 0 0 0 23 18.5v-1a4.555 4.555 0 0 0-4.5-4.5zm0 6a1.498 1.498 0 0 1-1.408-1 1.483 1.483 0 0 1-.092-.5 1.5 1.5 0 0 1 3 0 1.483 1.483 0 0 1-.092.5 1.498 1.498 0 0 1-1.408 1zm3.5-.5a.5.5 0 0 1-1 0v-3.447a3.639 3.639 0 0 1 1 2.447z" />
                    <path fill="none" d="M0 0h24v24H0z" />
                  </svg>
                  <em id="emailAddress">{email}</em>
                </div>
              </div>
              <div className="buttonCombo">
                <button className={`${!isUnsubscribe && "primary"}`} type="submit" onClick={changeSubscription}>
                  {t("Newsletter Einstellungen aktualisieren")}
                </button>
                <div className="buttonDescription" style={{ fontSize: "14px" }}>
                  <p>
                    {t(
                      "Hier können Sie ihre Einstellungen zum Empfang des BAMAC Newsletter anpassen. Durch die Deaktivierung einzelner Funktionen werden Sie aus dem Verteiler für den Themenbereich entfernt."
                    )}
                  </p>
                </div>
              </div>
              <Themes topics={topics} onChangeFunction={setTopics} />
              <div className="buttonCombo">
                <button id="unsubscribeButton" className={`${isUnsubscribe && "primary"}`} type="submit" onClick={() => setIsModalOpen(true)}>
                  {t("Newsletter abbestellen")}
                </button>
                <div className="buttonDescription" style={{ fontSize: "14px" }}>
                  <p>{t("Durch diesen Klick entfernen wir Sie aus dem Verteiler für einen jeglichen Newsletter.")}</p>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChangeSubscription;
