import React, { ChangeEvent, useEffect, useState } from "react";

export interface ICategory {
  category: string;
  topics: ITopic[];
}

export interface ITopic {
  title: string;
  description: string;
  isSubscribed: boolean;
}

interface IProps {
  topics: ICategory[];
  onChangeFunction: React.Dispatch<React.SetStateAction<ICategory[]>>;
}

export const Themes = ({ topics, onChangeFunction }: IProps) => {
  const [checkedCategories, setcheckedCategories] = useState<string[]>([]);
  const [expandedCategory, setExpandedCategory] = useState("");

  useEffect(() => {
    setcheckedCategories(topics.filter((topic) => !topic.topics.some((a_topic) => !a_topic.isSubscribed)).map((topic) => topic.category));
  }, [topics]);

  const handleOnChangeTopic = (a_categoryIndex: number, a_topicIndex: number, event: ChangeEvent) => {
    const newTopics = [...topics];
    newTopics[a_categoryIndex] = {
      ...topics[a_categoryIndex],
      topics: topics[a_categoryIndex].topics.map((topic, index) => {
        if (index !== a_topicIndex) {
          return topic;
        }
        return { ...topic, isSubscribed: !topic.isSubscribed };
      }),
    };

    onChangeFunction(newTopics);
  };

  const handleOnChangeCategory = (a_categoryIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newTopics = [...topics];
    newTopics[a_categoryIndex] = {
      ...topics[a_categoryIndex],
      topics: topics[a_categoryIndex].topics.map((topic) => {
        return { ...topic, isSubscribed: event.target.checked };
      }),
    };

    onChangeFunction(newTopics);
  };

  const renderCategory = (a_category: ICategory, a_index: number) => {
    return (
      <div className={"category"} key={`category_${a_index}_${a_category.category}`}>
        <div key={`category_${a_index}`}>
          <div className={"categoryHead"}>
            <div className="categoryLabel">
              <input
                className="switch"
                type={"checkbox"}
                id={`${a_category.category}_${a_index}`}
                checked={checkedCategories.includes(a_category.category)}
                onChange={(event) => handleOnChangeCategory(a_index, event)}
              />
              <label htmlFor={`${a_category.category}_${a_index}`}>
                <h4>{a_category.category}</h4>
              </label>
            </div>
            <button
              className="accordion"
              onClick={(event) => {
                event.preventDefault();
                setExpandedCategory(expandedCategory === a_category.category ? "" : a_category.category);
                if (expandedCategory !== a_category.category) {
                  const element = event.target as HTMLElement;
                  element?.scrollIntoView();
                }
              }}
            >
              {expandedCategory === a_category.category ? (
                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 15L12 9L6 15" stroke="#33363F" strokeWidth={2} />
                </svg>
              ) : (
                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 9L12 15L6 9" stroke="#33363F" strokeWidth={2} />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className={`topics ${expandedCategory === a_category.category ? "expanded" : ""}`}>
          {a_category.topics.map((topic, topicIndex) => renderTopic(a_index, topicIndex, topic))}
        </div>
      </div>
    );
  };

  const renderTopic = (a_categoryIndex: number, a_topicIndex: number, a_topic: ITopic) => {
    const key = `${a_categoryIndex}_${a_topicIndex}_${a_topic.title}`;
    return (
      <div key={key}>
        <input
          className="switch"
          type={"checkbox"}
          id={key}
          checked={a_topic.isSubscribed}
          onChange={(event) => handleOnChangeTopic(a_categoryIndex, a_topicIndex, event)}
        />
        <label htmlFor={key}>{a_topic.title}</label>
        <div className="description" dangerouslySetInnerHTML={{ __html: a_topic.description }}></div>
      </div>
    );
  };

  return <div className="categories">{topics.map((topic, index) => renderCategory(topic, index))}</div>;
};
