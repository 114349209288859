import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import { azureBaseUrl } from "../config";
import i18next from "i18next";
import Captcha from "./Captcha";
import ProgressBar, {changeSubscriptionSteps} from "./ProgressBar";

interface IProps {
  isUnsubscribe: boolean;
}

function RequestChange({ isUnsubscribe }: IProps) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [wasSubmitted, setWasSubmittet] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: false,
  });

  // Captcha
  const [euCaptchaToken, setEuCaptchaToken] = useState("");
  const [captchaImg, setCaptchaImg] = useState({ src: "", captchaId: "" });
  const [audioCaptcha, setAudioCaptcha] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [useAudio, setUseAudio] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const validateInputFields = (a_focus: boolean): boolean => {
    let newFormErrors = {
      email: false,
    };

    // Valideriung der Form-Fields
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newFormErrors.email = true;
    }

    setFormErrors(newFormErrors);

    if (newFormErrors.email) {
      if (a_focus) {
        if (newFormErrors.email) {
          document.getElementById("email")?.scrollIntoView({ block: "center" });
        }
      }
      return false;
    }
    return true;
  };

  // Validierung der Inputfelder
  useEffect(() => {
    if (wasSubmitted) {
      validateInputFields(false);
    }
  }, [email, wasSubmitted]);

  // UserMessage anzeigen und fokussieren
  useEffect(() => {
    if (userMessage !== "") {
      document.getElementById("userMessage")?.scrollIntoView({ block: "center" });
    }
  }, [userMessage]);

  useEffect(() => {
    if (requestCompleted !== false) {
      document.getElementById("successMessageWrapper")?.scrollIntoView({ block: "center" });
    }
  }, [userMessage]);

  const onChangeInput = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
    if (e.target === null) {
      return;
    }

    const { value } = e.target as HTMLInputElement;
    setEmail(value);
  };

  const onSubmitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setWasSubmittet(true);

    if (!validateInputFields(true)) {
      return;
    }

    setIsLoading(true);

    fetch(`${azureBaseUrl}RequestLink`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        email: email,
        captcha_id: captchaImg.captchaId,
        captcha_token: euCaptchaToken,
        captcha_audio: useAudio.toString(),
        captcha_answer: captchaAnswer,
        reason: isUnsubscribe ? "Abmeldung" : "Änderung",
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.captcha_invalid) {
              setCaptchaImg({ src: "", captchaId: "" });
              setIsCaptchaValid(false);
              document.getElementById("captchaWrapper")?.scrollIntoView({ block: "center" });
            } else if (data.success) {
              setRequestCompleted(true);
            } else {
              setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut.");
            }
          });

          return;
        }
        setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut.");
        return;
      })
      .catch(() => setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut."))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div id="content-wrap">
      {isLoading && (
        <div className="loadingDiv">
          <LoadingSpinner />
        </div>
      )}
      <div id="container" className="container">
        <div id="loadedContent">
          <div>
            <h2>{isUnsubscribe ? t("Newsletter abbestellen") : t("Newsletter-Einstellungen aktualisieren")}</h2>
          </div>
          <ProgressBar active={requestCompleted ? 1 : 0} steps={changeSubscriptionSteps} />
          {requestCompleted || userMessage !== "" ? (
            <React.Fragment>
              {requestCompleted ? (
                <div id="successMessageWrapper" className="successMessageWrapper">
                  <div style={{ marginTop: 40 }}>
                    {isUnsubscribe
                      ? t("In Kürze erhalten Sie eine E-Mail, um Ihre Abmeldung abschließen zu können.")
                      : t("In Kürze erhalten Sie eine E-Mail, um Ihre Newsletter-Einstellungen aktualisieren zu können.")}
                  </div>
                  <div className="notice">
                    {t("Sollte Sie keine Nachricht in Ihrem Posteingang finden, schauen Sie bitte in Ihrem Spam-Ordner nach.")}
                  </div>
                  <div className="backContainer">
                    <span>{t("Zurück zur Startseite der ")}</span>
                    <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
                      <img style={{ width: 120 }} src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
                    </a>
                  </div>
                </div>
              ) : userMessage ? (
                <div className="userMessageWrapper">
                  <div id="userMessage">{t(userMessage)}</div>
                  <div className="backContainer">
                    <span>{t("Zurück zur Startseite der ")}</span>
                    <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
                      <img style={{ width: 120 }} src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
                    </a>
                  </div>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isUnsubscribe ? (
                <span>
                  {t(
                    "Bitte tragen Sie Ihre E-Mail-Adresse ein. Anschließend erhalten Sie eine Nachricht mit einem Link, um Ihre Abmeldung abschließen zu können."
                  )}
                </span>
              ) : (
                <span>
                  {t(
                    "Bitte tragen Sie Ihre E-Mail-Adresse ein. Anschließend erhalten Sie eine Nachricht mit einem Link, um Ihre Einstellungen aktualisieren zu können."
                  )}
                </span>
              )}
              <form className="requestForm" onSubmit={(event) => onSubmitForm(event)}>
                <div className="formFields">
                  <div className="formField">
                    <label htmlFor="email">
                      {t("E-Mail")}
                      <span aria-hidden="true" className="required">
                        *
                      </span>
                    </label>
                    <input
                      id="email"
                      name="email"
                      value={email}
                      type="email"
                      required={true}
                      className={`${formErrors.email && "invalid"}`}
                      placeholder={t("Bitte geben Sie Ihre E-Mail-Adresse ein...") as string}
                      onChange={onChangeInput}
                    />
                    {formErrors.email && <span className="validationError">{t("Geben Sie bitte Ihre E-Mail-Adresse ein.")}</span>}
                  </div>
                </div>
                <div id="captchaWrapper" className={`captchaWrapper ${wasSubmitted && !isCaptchaValid && "invalid"}`}>
                  <Captcha
                    audioCaptcha={audioCaptcha}
                    setAudioCaptcha={setAudioCaptcha}
                    captchaAnswer={captchaAnswer}
                    setCaptchaAnswer={setCaptchaAnswer}
                    captchaImg={captchaImg}
                    setCaptchaImg={setCaptchaImg}
                    euCaptchaToken={euCaptchaToken}
                    setEuCaptchaToken={setEuCaptchaToken}
                    isValid={isCaptchaValid}
                    setIsValid={setIsCaptchaValid}
                    useAudio={useAudio}
                    setUseAudio={setUseAudio}
                  ></Captcha>
                </div>
                <div className="buttonCombo">
                  <button className={"primary"} title={t("Link anfordern") as string} type="submit" onClick={onSubmitForm}>
                    {t("Link anfordern")}
                  </button>
                </div>
              </form>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestChange;
