import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../ProgressBar.css";
interface IProps {
  active: number;
  steps?: Array<ISteps>
}
export interface ISteps {
  icon: JSX.Element;
  name: string;
}

export const registrationSteps: Array<ISteps> = [
  {
    name: "Registrierung",
    icon: (
      <svg fill="#ffffff" width="32px" height="30px" viewBox="-1 0 16 16" id="register-16px" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Path_184"
          data-name="Path 184"
          d="M57.5,41a.5.5,0,0,0-.5.5V43H47V31h2v.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V31h2v.5a.5.5,0,0,0,1,0v-1a.5.5,0,0,0-.5-.5H55v-.5A1.5,1.5,0,0,0,53.5,28h-3A1.5,1.5,0,0,0,49,29.5V30H46.5a.5.5,0,0,0-.5.5v13a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,57.5,41ZM50,29.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V31H50Zm11.854,4.646-2-2a.5.5,0,0,0-.708,0l-6,6A.5.5,0,0,0,53,38.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.354-.146l6-6A.5.5,0,0,0,61.854,34.146ZM54,40V38.707l5.5-5.5L60.793,34.5l-5.5,5.5Zm-2,.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,40.5Zm0-3a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,37.5ZM54.5,35h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z"
          transform="translate(-46 -28)"
        />
      </svg>
    ),
  },
  {
    name: "E-Mail bestätigen",
    icon: (
      <svg width="30px" height="30px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44 24V9H24H4V24V39H24" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31 36L36 40L44 30" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 9L24 24L44 9" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  },
  {
    name: "Anmeldung abgeschlossen",
    icon: (
      <svg width="30px" height="30px" fill="#ffffff" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>unlock-filled</title>
        <g stroke="none" fill="none">
          <g fill="#ffffff" transform="translate(85.333333, 42.666667)">
            <path d="M341.333333,426.666667 L341.333333,213.333333 L85.3333333,213.333333 L85.3333333,126.933333 C85.3333333,80.59584 123.33568,42.6666667 170.666667,42.6666667 C217.9968,42.6666667 256,80.59584 256,126.933333 L298.666667,126.933333 C298.666667,56.6282667 241.156267,3.55271368e-14 170.666667,3.55271368e-14 C100.176853,3.55271368e-14 42.6666667,56.6282667 42.6666667,126.933333 L42.6666667,213.333333 L1.42108547e-14,213.333333 L1.42108547e-14,426.666667 L341.333333,426.666667 Z"></path>
          </g>
        </g>
      </svg>
    ),
  },
];

export const changeSubscriptionSteps: Array<ISteps> = [
  {
    name: "Link anfordern",
    icon: (
      <svg fill="#ffffff" width="32px" height="30px" viewBox="-1 0 16 16" id="register-16px" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Path_184"
          data-name="Path 184"
          d="M57.5,41a.5.5,0,0,0-.5.5V43H47V31h2v.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V31h2v.5a.5.5,0,0,0,1,0v-1a.5.5,0,0,0-.5-.5H55v-.5A1.5,1.5,0,0,0,53.5,28h-3A1.5,1.5,0,0,0,49,29.5V30H46.5a.5.5,0,0,0-.5.5v13a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,57.5,41ZM50,29.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5V31H50Zm11.854,4.646-2-2a.5.5,0,0,0-.708,0l-6,6A.5.5,0,0,0,53,38.5v2a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.354-.146l6-6A.5.5,0,0,0,61.854,34.146ZM54,40V38.707l5.5-5.5L60.793,34.5l-5.5,5.5Zm-2,.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,40.5Zm0-3a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,52,37.5ZM54.5,35h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z"
          transform="translate(-46 -28)"
        />
      </svg>
    ),
  },
  {
    name: "E-Mail bestätigen",
    icon: (
      <svg width="30px" height="30px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44 24V9H24H4V24V39H24" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31 36L36 40L44 30" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 9L24 24L44 9" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  },
  {
    name: "Änderungen vornehmen",
    icon: (
      <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.48 18.5368H21M4.68 12L3 12.044M4.68 12C4.68 13.3255 5.75451 14.4 7.08 14.4C8.40548 14.4 9.48 13.3255 9.48 12C9.48 10.6745 8.40548 9.6 7.08 9.6C5.75451 9.6 4.68 10.6745 4.68 12ZM10.169 12.0441H21M12.801 5.55124L3 5.55124M21 5.55124H18.48M3 18.5368H12.801M17.88 18.6C17.88 19.9255 16.8055 21 15.48 21C14.1545 21 13.08 19.9255 13.08 18.6C13.08 17.2745 14.1545 16.2 15.48 16.2C16.8055 16.2 17.88 17.2745 17.88 18.6ZM17.88 5.4C17.88 6.72548 16.8055 7.8 15.48 7.8C14.1545 7.8 13.08 6.72548 13.08 5.4C13.08 4.07452 14.1545 3 15.48 3C16.8055 3 17.88 4.07452 17.88 5.4Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
</svg>
    ),
  },
];

const ProgressBar = ({ active, steps }: IProps) => {
  const { t } = useTranslation();
  const stepsToShow = steps ?? registrationSteps;
  return (
    <React.Fragment>
      <ul className="progressbar">
        {stepsToShow.map((step, index) => {
          return (
            <li key={`step_${index}`} className={`progressbar_step ${index <= active ? "active" : ""}`}>
              <div className={`status ${index === active ? "nextStep" : ""}`}>
                {index < active ? (
                  <svg width="15px" height="15px" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.716 4.386a1 1 0 0 1 1.572 1.236L10.665 19.136a1.5 1.5 0 0 1-2.324.042l-5.104-6.032a1 1 0 1 1 1.526-1.292l4.708 5.564L19.716 4.386z"
                      fill="#ffffff"
                    />
                  </svg>
                ) : index === active ? (
                  <svg
                    fill="#ffffff"
                    width="15px"
                    height="15px"
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S17.654,13,16,13z" />
                    <path d="M6,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S7.654,13,6,13z" />
                    <path d="M26,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S27.654,13,26,13z" />
                  </svg>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <div>{step.icon}</div>
                <p>{t(step.name)}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default ProgressBar;
