import React, { useEffect, useState } from "react";
import { Themes, ICategory } from "./Themes";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import { useLocation } from "react-router-dom";
import { azureBaseUrl } from "../config";
import i18next from "i18next";
import Captcha from "./Captcha";
import ProgressBar from "./ProgressBar";

function Registration() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const [wasSubmitted, setWasSubmittet] = useState(false);
  const [topics, setTopics] = useState<ICategory[]>([]);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const location = useLocation();

  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    salutation: "",
    personTitle: "",
    company: "",
    privacyPolicy: false,
  });

  const [formErrors, setFormErrors] = useState({
    email: false,
    firstName: false,
    lastName: false,
    salutation: false,
    personTitle: false,
    company: false,
    privacyPolicy: false,
  });

  // Captcha
  const [euCaptchaToken, setEuCaptchaToken] = useState("");
  const [captchaImg, setCaptchaImg] = useState({ src: "", captchaId: "" });
  const [audioCaptcha, setAudioCaptcha] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [useAudio, setUseAudio] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const validateInputFields = (a_focus: boolean): boolean => {
    let newFormErrors = {
      email: false,
      firstName: false,
      lastName: false,
      salutation: false,
      personTitle: false,
      company: false,
      privacyPolicy: false,
    };

    // Valideriung der Form-Fields
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newFormErrors.email = true;
    }
    if (firstName === "") {
      newFormErrors.firstName = true;
    }
    if (lastName === "") {
      newFormErrors.lastName = true;
    }
    if (!privacyPolicy) {
      newFormErrors.privacyPolicy = true;
    }

    setFormErrors(newFormErrors);

    if (newFormErrors.email || newFormErrors.firstName || newFormErrors.lastName || newFormErrors.privacyPolicy) {
      if (a_focus) {
        if (newFormErrors.email) {
          document.getElementById("email")?.scrollIntoView({ block: "center" });
        } else if (newFormErrors.firstName) {
          document.getElementById("firstName")?.scrollIntoView({ block: "center" });
        } else if (newFormErrors.lastName) {
          document.getElementById("lastName")?.scrollIntoView({ block: "center" });
        } else {
          document.getElementById("privacyPolicyLabel")?.scrollIntoView({ block: "center" });
        }
      }
      return false;
    }
    return true;
  };

  // Initial und bei einem Pfadwechsel müssen die Topics geladen werden
  useEffect(() => {
    // Daten über Azure-Function abrufen
    fetch(`${azureBaseUrl}gettopics?language=${i18next.language}`)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => setTopics(data));
        } else {
          setUserMessage("Beim Abrufen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
        }
      })
      .catch(() => setUserMessage("Beim Abrufen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."))
      .finally(() => setIsLoading(false));
  }, [location.pathname]);

  // Validierung der Inputfelder
  useEffect(() => {
    if (wasSubmitted) {
      validateInputFields(false);
    }
  }, [user, wasSubmitted]);

  // UserMessage anzeigen und fokussieren
  useEffect(() => {
    if (userMessage !== "") {
      document.getElementById("userMessage")?.scrollIntoView({ block: "center" });
    }
  }, [userMessage]);
  useEffect(() => {
    if (registrationCompleted !== false) {
      document.getElementById("successMessageWrapper")?.scrollIntoView({ block: "center" });
    }
  }, [userMessage]);

  const onChangeInput = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
    if (e.target === null) {
      return;
    }

    const { name, value, type, checked } = e.target as HTMLInputElement;
    setUser({ ...user, [name]: type !== "checkbox" ? value : checked });
  };

  const onSubmitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setWasSubmittet(true);

    if (!validateInputFields(true)) {
      return;
    }

    /* if (!isCaptchaValid) {
      document.getElementById("captchaWrapper")?.scrollIntoView({ block: "center" });
      return;
    } */

    setIsLoading(true);

    // Auswahl der Themen abrufen
    const selectedTopics: string[] = [];
    topics.forEach((a_category) => {
      a_category.topics.forEach((topic) => {
        if (topic.isSubscribed) {
          selectedTopics.push(topic.title);
        }
      });
    });

    fetch(`${azureBaseUrl}subscribe`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
        salutation: salutation,
        personTitle: personTitle,
        company: company,
        topics: selectedTopics,
        language: i18next.language,
        captcha_id: captchaImg.captchaId,
        captcha_token: euCaptchaToken,
        captcha_audio: useAudio.toString(),
        captcha_answer: captchaAnswer,
        privacy_policy: privacyPolicy,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.captcha_invalid) {
              setCaptchaImg({ src: "", captchaId: "" });
              setIsCaptchaValid(false);
              document.getElementById("captchaWrapper")?.scrollIntoView({ block: "center" });
            } else if (data.already_subscribed) {
              setUserMessage("Sie sind bereits für den Newsletter registriert.");
            } else if (data.success) {
              setRegistrationCompleted(true);
            } else {
              setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut.");
            }
          });

          return;
        }
        setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut.");
        return;
      })
      .catch(() => setUserMessage("Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut."))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { email, firstName, lastName, salutation, personTitle, company, privacyPolicy } = user;

  return (
    <div id="content-wrap">
      {isLoading && (
        <div className="loadingDiv">
          <LoadingSpinner />
        </div>
      )}
      <div id="container" className="container">
        <div id="loadedContent">
          <div>
            <h2>{t("Registrierung für den Newsletter")}</h2>
          </div>
          {registrationCompleted || userMessage !== "" ? (
            <React.Fragment>
              {registrationCompleted ? (
                <div id="successMessageWrapper" className="successMessageWrapper">
                  <ProgressBar active={1} />
                  <div style={{ marginTop: 40 }}>
                    {t(
                      "Haben Sie herzlichen Dank für die Anmeldung zum Newsletter! In Kürze erhalten Sie eine E-Mail, um Ihre Registrierung zu finalisieren."
                    )}
                  </div>
                  <div className="notice">
                    {t(
                      "Bitte bestätigen Sie Ihre Anmeldung durch einen Klick auf den Link in der E-Mail, die wir Ihnen soeben zugeschickt haben. Sollte Sie keine Nachricht in Ihrem Posteingang finden, schauen Sie bitte in Ihrem Spam-Ordner nach. "
                    )}
                  </div>
                  <div className="backContainer">
                    <span>{t("Zurück zur Startseite der ")}</span>
                    <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
                      <img style={{ width: 120 }} src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
                    </a>
                  </div>
                </div>
              ) : userMessage ? (
                <div className="userMessageWrapper">
                  <div id="userMessage">{t(userMessage)}</div>
                  <div className="backContainer">
                    <span>{t("Zurück zur Startseite der ")}</span>
                    <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
                      <img style={{ width: 120 }} src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
                    </a>
                  </div>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ProgressBar active={0} />
              <form className="registrationForm" onSubmit={(event) => onSubmitForm(event)}>
                <div className="formFields">
                  <div className="formField">
                    <label htmlFor="email">
                      {t("E-Mail")}
                      <span aria-hidden="true" className="required">
                        *
                      </span>
                    </label>
                    <input
                      id="email"
                      name="email"
                      value={email}
                      type="email"
                      required={true}
                      className={`${formErrors.email && "invalid"}`}
                      placeholder={t("Bitte geben Sie Ihre E-Mail-Adresse ein...") as string}
                      onChange={onChangeInput}
                    />
                    {formErrors.email && <span className="validationError">{t("Geben Sie bitte Ihre E-Mail-Adresse ein.")}</span>}
                  </div>
                  <div className="formField">
                    <label htmlFor={"firstName"}>
                      {t("Vorname")}
                      <span aria-hidden="true" className="required">
                        *
                      </span>
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      type="text"
                      required={true}
                      className={`${formErrors.firstName && "invalid"}`}
                      placeholder={t("Bitte geben Sie Ihren Vornamen ein...") as string}
                      onChange={onChangeInput}
                    />
                    {formErrors.firstName && <span className="validationError">{t("Geben Sie bitte Ihren Vornamen ein.")}</span>}
                  </div>
                  <div className="formField">
                    <label htmlFor={"lastName"}>
                      {t("Nachname")}
                      <span aria-hidden="true" className="required">
                        *
                      </span>
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      type="text"
                      required={true}
                      className={`${formErrors.lastName && "invalid"}`}
                      placeholder={t("Bitte geben Sie Ihren Nachnamen ein...") as string}
                      onChange={onChangeInput}
                    />
                    {formErrors.lastName && <span className="validationError">{t("Geben Sie bitte Ihren Nachnamen ein.")}</span>}
                  </div>
                  <div className="formField">
                    <label htmlFor="salutation">{t("Anrede")}</label>
                    <select
                      id="salutation"
                      name="salutation"
                      value={salutation}
                      required={true}
                      className={`${formErrors.salutation && "invalid"}`}
                      onChange={onChangeInput}
                    >
                      <option value="-">-</option>
                      {i18next.language === "en" ? (
                        <React.Fragment>
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Mss">Mss</option>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                        </React.Fragment>
                      )}
                    </select>
                  </div>
                  <div className="formField">
                    <label htmlFor={"personTitle"}>{t("Titel")}</label>
                    <input
                      id="personTitle"
                      name="personTitle"
                      value={personTitle}
                      type="text"
                      required={false}
                      className={`${formErrors.personTitle && "invalid"}`}
                      placeholder={t("Bitte geben Sie Ihren Titel ein...") as string}
                      onChange={onChangeInput}
                    />
                  </div>
                  <div className="formField">
                    <label htmlFor={"company"}>{t("Unternehmen")}</label>
                    <input
                      id="company"
                      name="company"
                      value={company}
                      type="text"
                      required={false}
                      className={`${formErrors.company && "invalid"}`}
                      placeholder={t("Bitte geben Sie den Namen Ihres Unternehmens ein...") as string}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="formField legend">
                  <label>{t("Auswahl der Themenbereiche")}</label>
                </div>
                <Themes topics={topics} onChangeFunction={setTopics} />
                <div className="formField checkbox">
                  <legend>{t("Datenschutzerklärung")} *</legend>
                  <input
                    id="privacyPolicy"
                    name="privacyPolicy"
                    value={"true"}
                    checked={privacyPolicy}
                    type={"checkbox"}
                    required={true}
                    className={`${formErrors.privacyPolicy && "invalid"}`}
                    onChange={onChangeInput}
                  />
                  <label id={"privacyPolicyLabel"} htmlFor={"privacyPolicy"}>
                    {i18next.language === "de" ? (
                      <span>
                        Durch absenden des Formulars bestätigen Sie, dass Sie die{" "}
                        <a href="https://bamacgroup.de/datenschutz" target="_blank" rel="noreferrer">
                          <span>Datenschutzerklärung</span>
                        </a>{" "}
                        zur Kenntnis genommen haben und mit der Verarbeitung Ihrer personenbezogenen Daten durch die BAMAC Group GmbH, zum Zwecke des
                        Versands des Newsletters, einverstanden sind.
                      </span>
                    ) : (
                      <span>
                        By submitting the form, you confirm that you have read the{" "}
                        <a href="https://www.bamacgroup.com/en/data-privacy" target="_blank" rel="noreferrer">
                          <span>privacy policy</span>
                        </a>{" "}
                        and agree to the processing of your personal data by BAMAC Group GmbH for the purpose of sending the newsletter.
                      </span>
                    )}
                  </label>
                  {formErrors.privacyPolicy && <div className="validationError">{t("Stimmen Sie bitte der Datenschutzerklärung zu.")}</div>}
                </div>
                <div id="captchaWrapper" className={`captchaWrapper ${wasSubmitted && !isCaptchaValid && "invalid"}`}>
                  <Captcha
                    audioCaptcha={audioCaptcha}
                    setAudioCaptcha={setAudioCaptcha}
                    captchaAnswer={captchaAnswer}
                    setCaptchaAnswer={setCaptchaAnswer}
                    captchaImg={captchaImg}
                    setCaptchaImg={setCaptchaImg}
                    euCaptchaToken={euCaptchaToken}
                    setEuCaptchaToken={setEuCaptchaToken}
                    isValid={isCaptchaValid}
                    setIsValid={setIsCaptchaValid}
                    useAudio={useAudio}
                    setUseAudio={setUseAudio}
                  ></Captcha>
                </div>
                <div className="buttonCombo">
                  <button className={"primary"} title={t("Anmelden") as string} type="submit" onClick={onSubmitForm}>
                    {t("Für den Newsletter anmelden")}
                  </button>
                </div>
              </form>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default Registration;
