import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.on("languageChanged", function (lng) {
  if (i18n.options.fallbackLng && lng === i18n.options.fallbackLng) {
    if (window.location.pathname.includes("/" + i18n.options.fallbackLng)) {
      const newUrl = window.location.pathname.replace("/" + i18n.options.fallbackLng, "");
      window.location.replace(newUrl);
    }
  }
});

const languageDetector = new LanguageDetector(null, {
  order: ["path"],
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          Newsletter: "Newsletter",
          Datenschutzerklärung: "Privacy policy",
          Impressum: "Imprint",
          "Newsletter-Einstellungen werden geladen....": "Newsletter settings are loaded",
          "Registrierung für den Newsletter": "Newsletter registration",
          Vorname: "First name",
          Nachname: "Last name",
          "Bitte geben Sie Ihre E-Mail-Adresse ein...": "Please enter your email address...",
          "Bitte geben Sie Ihren Vornamen ein...": "Please enter your first name...",
          "Bitte geben Sie Ihren Nachnamen ein...": "Please enter your last name...",
          "Für den Newsletter anmelden": "Sign up for the newsletter",
          "Newsletter Einstellungen aktualisieren": "Update newsletter settings",
          "Hier können Sie ihre Einstellungen zum Empfang des BAMAC Newsletter anpassen. Durch die Deaktivierung einzelner Funktionen werden Sie aus dem Verteiler für den Themenbereich entfernt.":
            "Here you can adjust your settings for receiving the BAMAC newsletter. By deactivating individual functions you will be removed from the distribution list for the topic.",
          "Newsletter abbestellen": "Unsubscribe newsletter",
          "Durch diesen Klick entfernen wir Sie aus dem Verteiler für einen jeglichen Newsletter.":
            "By clicking this, we will remove you from the mailing list for any newsletter.",
          "Auswahl der Themenbereiche": "Selection of topics",
          "E-Mail": "E-mail",
          "Ihre Anpassung wurden erfolgreich gespeichert.": "Your customization has been saved successfully.",
          "Ihr Link ist ungültig.": "Your link is invalid.",
          "Es liegt keine Newslettersubscription vor.": "There is no newsletter subscription.",
          "Beim Abrufen der Seite ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.":
            "An unexpected error occurred while retrieving the page. Please try again.",
          Anrede: "Salutation",
          "Bitte geben Sie den Namen Ihren Firma ein...": "Please enter the name of your company...",
          Firma: "Company",
          Unternehmen: "Company",
          "Bitte geben Sie den Namen Ihres Unternehmens ein...": "Please enter the name of your company...",
          Anmelden: "Sign up",
          "Bitte Text vom Bild eingeben": "Enter text from image",
          "Beim Abrufen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.":
            "An error occurred during retrieval. Please try again later.",
          "Haben Sie herzlichen Dank für die Anmeldung zum Newsletter! In Kürze erhalten Sie eine E-Mail, um Ihre Registrierung zu finalisieren.":
            "Thank you very much for subscribing to the newsletter! Shortly you will receive an email to finalize your registration.",
          "Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es in kürze erneut.":
            "Your request could not be processed. Please try again shortly.",
          "Durch absenden des Formulars bestätigen Sie, dass Sie die Datenschutzerklärung zur Kenntnis genommen haben und mit der Verarbeitung Ihrer personenbezogenen Daten durch die BAMAC Group GmbH, zum Zwecke des Versands des Newsletters, einverstanden sind.":
            "By submitting the form, you confirm that you have read the privacy policy and agree to the processing of your personal data by BAMAC Group GmbH for the purpose of sending the newsletter.",
          "Captcha neuladen": "Reload the Captcha",
          "Captcha ist gültig": "Captcha is valid",
          "Captcha ist ungültig": "Captcha is invalid",
          Anhalten: "Pause",
          Abspielen: "Play",
          "Captcha-Eingabe zur Lösung": "Captcha input for solution",
          "Wollen Sie sich wirklich abmelden?": "Do you really want to unsubscribe?",
          Schließen: "Close",
          Abmelden: "Unsubscribe",
          "Zurück zur Startseite der ": "Return to",
          "Sie wurden erfolgreich vom Newsletter abgemeldet.": "You have been successfully unsubscribed from the newsletter.",
          "Sie sind bereits für den Newsletter registriert.": "You are already registered for the newsletter.",
          Titel: "Title",
          "Bitte geben Sie Ihren Titel ein...": "Please enter your title...",
          "Geben Sie bitte Ihre E-Mail-Adresse ein.": "Please enter your email address.",
          "Geben Sie bitte Ihren Vornamen ein.": "Please enter your first name.",
          "Stimmen Sie bitte der Datenschutzerklärung zu.": "Please agree to the privacy policy.",
          "Geben Sie bitte Ihren Nachnamen ein.": "Please enter your last name.",
          Registrierung: "Registration",
          "E-Mail bestätigen": "Confirm email",
          "Anmeldung abgeschlossen": "Registration completed",
          "Bitte bestätigen Sie Ihre Anmeldung durch einen Klick auf den Link in der E-Mail, die wir Ihnen soeben zugeschickt haben. Sollte Sie keine Nachricht in Ihrem Posteingang finden, schauen Sie bitte in Ihrem Spam-Ordner nach. ":
            "Please confirm your registration by clicking on the link in the email we just sent you. If you do not find a message in your inbox, please check your spam folder.",
          "Die Bestätigung Ihrer Newsletter Registrierung ist leider fehlgeschlagen. Bitte versuchen Sie es später erneut.":
            "Sorry, the confirmation of your newsletter registration failed. Please try again later.",
          "Vielen Dank für Ihre Newsletter Anmeldung": "Thank you for subscribing to our newsletter",
          //RequestChange
          "Newsletter-Einstellungen aktualisieren": "Update newsletter settings",
          "In Kürze erhalten Sie eine E-Mail, um Ihre Abmeldung abschließen zu können.":
            "In Kürze erhalten Sie eine E-Mail, um Ihre Abmeldung abschließen zu können.",
          "In Kürze erhalten Sie eine E-Mail, um Ihre Newsletter-Einstellungen aktualisieren zu können.":
            "You will receive an email shortly to update your newsletter settings.",
          "Bitte tragen Sie Ihre E-Mail-Adresse ein. Anschließend erhalten Sie eine Nachricht mit einem Link, um Ihre Abmeldung abschließen zu können.":
            "Please enter your e-mail address. You will then receive a message with a link to complete your unsubscribe.",
          "Bitte tragen Sie Ihre E-Mail-Adresse ein. Anschließend erhalten Sie eine Nachricht mit einem Link, um Ihre Einstellungen aktualisieren zu können.":
            "Please enter your e-mail address. You will then receive a message with a link to update your settings.",
          "Link anfordern": "Request link",
          "Änderungen vornehmen":"Make changes",
          "Sollte Sie keine Nachricht in Ihrem Posteingang finden, schauen Sie bitte in Ihrem Spam-Ordner nach.":"If you do not find a message in your inbox, please check your spam folder."
        },
      },
      de: {
        translation: {},
      },
    },
    supportedLngs: ["en", "de"],
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

export default i18n;
