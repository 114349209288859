import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { captchaBaseUri } from "../config";
import i18next from "i18next";

import "../captcha.css";
import LoadingSpinner from "./LoadingSpinner";

interface IProps {
  euCaptchaToken: string;
  setEuCaptchaToken: React.Dispatch<React.SetStateAction<string>>;
  captchaImg: ICaptchaImg;
  setCaptchaImg: React.Dispatch<React.SetStateAction<ICaptchaImg>>;
  captchaAnswer: string;
  setCaptchaAnswer: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  useAudio: boolean;
  setUseAudio: React.Dispatch<React.SetStateAction<boolean>>;
  audioCaptcha: string;
  setAudioCaptcha: React.Dispatch<React.SetStateAction<string>>;
}

interface ICaptchaImg {
  src: string;
  captchaId: string;
}

function Captcha({
  setEuCaptchaToken,
  captchaImg,
  setCaptchaImg,
  captchaAnswer,
  setCaptchaAnswer,
  audioCaptcha,
  setAudioCaptcha,
  setUseAudio,
}: IProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (captchaImg.src.length === 0) {
      getcaptcha();
    }
  }, [captchaImg]);

  useEffect(() => {
    if (captchaImg.src.length > 0) {
      reloadCaptcha();
    }
  }, [i18next.language]);

  const getcaptcha = () => {
    setIsLoading(true);
    const language = i18next.language === "de" ? "de-DE" : "en-GB";

    fetch(captchaBaseUri + "captchaImg?locale=" + language, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setEuCaptchaToken(response.headers.get("x-jwtString") as string);
            setCaptchaImg({ src: "data:image/png;base64," + data.captchaImg, captchaId: data.captchaId });
            setAudioCaptcha("data:audio/wav;base64," + data.audioCaptcha);
            setIsAudioPlaying(false);
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setCaptchaAnswer("");
      });
  };

  const reloadCaptcha = () => {
    setIsLoading(true);
    const language = i18next.language === "de" ? "de-DE" : "en-GB";
    fetch(captchaBaseUri + "reloadCaptchaImg/" + captchaImg.captchaId + "/?locale=" + language, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setEuCaptchaToken(response.headers.get("x-jwtString") as string);
            setCaptchaImg({ src: "data:image/png;base64," + data.captchaImg, captchaId: data.captchaId });
            setAudioCaptcha("data:audio/wav;base64," + data.audioCaptcha);
            setCaptchaAnswer("");
            setIsAudioPlaying(false);
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const captchaButton = () => {
    return (
      <button
        title={isLoading ? (t("Lade") as string) : (t("Captcha neuladen") as string)}
        id="captchaReload"
        onClick={(event) => {
          event.preventDefault();
          !isLoading && reloadCaptcha();
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 18.5H15M6.6 2H17.4C17.9601 2 18.2401 2 18.454 2.10899C18.6422 2.20487 18.7951 2.35785 18.891 2.54601C19 2.75992 19 3.03995 19 3.6V5.67452C19 6.1637 19 6.40829 18.9447 6.63846C18.8957 6.84254 18.8149 7.03763 18.7053 7.21657C18.5816 7.4184 18.4086 7.59135 18.0627 7.93726L15.1314 10.8686C14.7354 11.2646 14.5373 11.4627 14.4632 11.691C14.3979 11.8918 14.3979 12.1082 14.4632 12.309C14.5373 12.5373 14.7354 12.7354 15.1314 13.1314L18.0627 16.0627C18.4086 16.4086 18.5816 16.5816 18.7053 16.7834C18.8149 16.9624 18.8957 17.1575 18.9447 17.3615C19 17.5917 19 17.8363 19 18.3255V20.4C19 20.9601 19 21.2401 18.891 21.454C18.7951 21.6422 18.6422 21.7951 18.454 21.891C18.2401 22 17.9601 22 17.4 22H6.6C6.03995 22 5.75992 22 5.54601 21.891C5.35785 21.7951 5.20487 21.6422 5.10899 21.454C5 21.2401 5 20.9601 5 20.4V18.3255C5 17.8363 5 17.5917 5.05526 17.3615C5.10425 17.1575 5.18506 16.9624 5.29472 16.7834C5.4184 16.5816 5.59135 16.4086 5.93726 16.0627L8.86863 13.1314C9.26465 12.7354 9.46265 12.5373 9.53684 12.309C9.6021 12.1082 9.6021 11.8918 9.53684 11.691C9.46266 11.4627 9.26464 11.2646 8.86863 10.8686L5.93726 7.93726C5.59136 7.59136 5.4184 7.4184 5.29472 7.21657C5.18506 7.03763 5.10425 6.84254 5.05526 6.63846C5 6.40829 5 6.1637 5 5.67452V3.6C5 3.03995 5 2.75992 5.10899 2.54601C5.20487 2.35785 5.35785 2.20487 5.54601 2.10899C5.75992 2 6.03995 2 6.6 2Z"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.453 12.8932C20.1752 15.5031 18.6964 17.9488 16.2494 19.3616C12.1839 21.7088 6.98539 20.3158 4.63818 16.2503L4.38818 15.8173M3.54613 11.1071C3.82393 8.49723 5.30272 6.05151 7.74971 4.63874C11.8152 2.29153 17.0137 3.68447 19.3609 7.74995L19.6109 8.18297M3.49316 18.0662L4.22521 15.3341L6.95727 16.0662M17.0424 7.93413L19.7744 8.66618L20.5065 5.93413"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </button>
    );
  };
  return (
    <div className="captchaContainer ">
      {captchaImg.src === "" ? (
        <div className="loadingWrapper">
          <span>Das Captcha wird geladen...</span>
          <LoadingSpinner fullScreen={false}></LoadingSpinner>
        </div>
      ) : (
        <React.Fragment>
          <div className="audioControl">
            <label htmlFor="audioControl" title={isAudioPlaying ? (t("Anhalten") as string) : (t("Abspielen") as string)}>
              {isAudioPlaying ? (
                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H6.8C7.9201 21 8.48016 21 8.90798 20.782C9.28431 20.5903 9.59027 20.2843 9.78201 19.908C10 19.4802 10 18.9201 10 17.8V6.2C10 5.0799 10 4.51984 9.78201 4.09202C9.59027 3.71569 9.28431 3.40973 8.90798 3.21799C8.48016 3 7.9201 3 6.8 3Z"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.8 3H17.2C16.0799 3 15.5198 3 15.092 3.21799C14.7157 3.40973 14.4097 3.71569 14.218 4.09202C14 4.51984 14 5.0799 14 6.2V17.8C14 18.9201 14 19.4802 14.218 19.908C14.4097 20.2843 14.7157 20.5903 15.092 20.782C15.5198 21 16.0799 21 17.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3Z"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 4.99993C21.1652 6.97016 22 9.38756 22 11.9999C22 14.6123 21.1652 17.0297 19.7479 18.9999M15.7453 7.99993C16.5362 9.13376 17 10.5127 17 11.9999C17 13.4872 16.5362 14.8661 15.7453 15.9999M9.63432 5.36561L6.46863 8.5313C6.29568 8.70425 6.2092 8.79073 6.10828 8.85257C6.01881 8.9074 5.92127 8.9478 5.81923 8.9723C5.70414 8.99993 5.58185 8.99993 5.33726 8.99993H3.6C3.03995 8.99993 2.75992 8.99993 2.54601 9.10892C2.35785 9.20479 2.20487 9.35777 2.10899 9.54594C2 9.75985 2 10.0399 2 10.5999V13.3999C2 13.96 2 14.24 2.10899 14.4539C2.20487 14.6421 2.35785 14.7951 2.54601 14.8909C2.75992 14.9999 3.03995 14.9999 3.6 14.9999H5.33726C5.58185 14.9999 5.70414 14.9999 5.81923 15.0276C5.92127 15.0521 6.01881 15.0925 6.10828 15.1473C6.2092 15.2091 6.29568 15.2956 6.46863 15.4686L9.63431 18.6342C10.0627 19.0626 10.2769 19.2768 10.4608 19.2913C10.6203 19.3038 10.7763 19.2392 10.8802 19.1175C11 18.9773 11 18.6744 11 18.0686V5.9313C11 5.32548 11 5.02257 10.8802 4.88231C10.7763 4.76061 10.6203 4.69602 10.4608 4.70858C10.2769 4.72305 10.0627 4.93724 9.63432 5.36561Z"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </label>
            <input
              id="audioControl"
              className="audioControlIcon"
              type={"button"}
              onClick={() => {
                const audioElement = document.getElementById("audioCaptcha") as HTMLMediaElement;
                if (!audioElement) {
                  return;
                }
                if (!isAudioPlaying) {
                  audioElement.play();
                } else {
                  audioElement.pause();
                }
              }}
            />
          </div>
          <img alt="Captcha to solve" src={captchaImg.src ?? "data:,"} id="captchaImg" />
          <audio
            controls
            src={audioCaptcha ?? undefined}
            style={{ display: "none" }}
            id="audioCaptcha"
            onPause={() => setIsAudioPlaying(false)}
            onPlay={() => {
              setUseAudio(true);
              setIsAudioPlaying(true);
            }}
          ></audio>
          <div className="captchaInputContainer">
            <input
              title={t("Captcha-Eingabe zur Lösung") as string}
              type="text"
              className="form-control"
              value={captchaAnswer}
              onChange={(event) => setCaptchaAnswer(event.target.value)}
              id="captchaAnswer"
              maxLength={8}
              placeholder={t("Bitte Text vom Bild eingeben") as string}
            />
            {captchaButton()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Captcha;
