import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import ChangeSubscription from "./components/ChangeSubscription";
import ConfirmSubscription from "./components/ConfirmSubscription";
import Registration from "./components/Registration";
import RequestChange from "./components/RequestChange";

function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="App">
      <header>
        <div className="blur">
          <a href={i18next.language === "en" ? "https://bamacgroup.com" : "https://bamacgroup.de"}>
            <img className="logo" src={process.env.PUBLIC_URL + "/Logo.png"} alt="BAMAC Group" />
            <img className="logoMobile" src={process.env.PUBLIC_URL + "/MobileLogo.png"} alt="BAMAC Group" />
          </a>
          <div className="languageSwitcher">
            <button
              className="language"
              disabled={i18next.language === "de"}
              onClick={() => {
                navigate(`/de${location.pathname.substring(3)}${location.search}`, { replace: true });
                i18next.changeLanguage("de");
              }}
            >
              Deutsch
            </button>
            |
            <button
              className="language"
              disabled={i18next.language === "en"}
              onClick={() => {
                navigate(`/en${location.pathname.substring(3)}${location.search}`);
                i18next.changeLanguage("en");
              }}
            >
              Englisch
            </button>
          </div>
        </div>
        <div className="headerBackground">
          <img className="backgroundImage" src={process.env.PUBLIC_URL + "/background.jpg"} alt="Hintergrundbild" />
        </div>
      </header>
      <div id="page-container">
        <Routes>
          <Route path="/:lang/register" element={<Registration />} />
          <Route path="/:lang/unsubscribe" element={<ChangeSubscription isUnsubscribe={true} />} />
          <Route path="/:lang/changeSubscription" element={<ChangeSubscription isUnsubscribe={false} />} />
          <Route path="/:lang/confirmSubscription" element={<ConfirmSubscription />} />
          <Route path="/:lang/requestChange" element={<RequestChange isUnsubscribe={false} />} />
          <Route path="/:lang/requestUnsubscribe" element={<RequestChange isUnsubscribe={true} />} />
          <Route path="*" element={<Navigate to={`/${i18next.language}/register`} replace={true} />} />
        </Routes>
      </div>
      <footer>
        <div className="footer-container">
          <img className="footerImage" src={process.env.PUBLIC_URL + "/footerImg.jpg"} alt="Hintergrundbild" />
          <div className="footer-content">
            <div>
              <script>document.write(new Date().getFullYear());</script>BAMAC Group GmbH © All rights reserved.
            </div>
            <div>
              <a
                href={i18next.language === "en" ? "https://www.bamacgroup.com/en/imprint" : "https://bamacgroup.de/impressum"}
                target="_blank"
                rel="noreferrer"
              >
                <span>{t("Impressum")}</span>
              </a>
              -
              <a
                href={i18next.language === "en" ? "https://www.bamacgroup.com/en/data-privacy" : "https://bamacgroup.de/datenschutz"}
                target="_blank"
                rel="noreferrer"
              >
                <span>{t("Datenschutzerklärung")}</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
