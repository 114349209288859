import React from "react";

interface IProps {
  fullScreen?: boolean;
}

const LoadingSpinner = ({ fullScreen = true }: IProps) => {
  return (
    <React.Fragment>
      {fullScreen ? (
        <div className="loadingDiv">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoadingSpinner;
