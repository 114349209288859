import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import LoadingSpinner from "./LoadingSpinner";

interface IProps {
  title: string;
  isOpen: boolean;
  askToClose: Function;
  primaryAction: Function;
}

Modal.setAppElement("#root");

const ModalScreen = ({ title, isOpen, askToClose, primaryAction }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      id="modalId"
      contentLabel="modalA"
      closeTimeoutMS={150}
      isOpen={isOpen}
      style={{
        overlay: { zIndex: "99" },
        content: {
          maxWidth: "400px",
          maxHeight: "200px",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        },
      }}
    >
      {isLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <React.Fragment>
          <h4>{title}</h4>
          <div className="modalButtonContainer">
            <button onClick={() => askToClose()}>{t("Schließen")}</button>
            <button
              className="primary"
              onClick={() => {
                setIsLoading(true);
                primaryAction();
                setIsLoading(false);
                askToClose();
              }}
            >
              {t("Abmelden")}
            </button>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default ModalScreen;
